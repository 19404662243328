<template>
    <div class="fl-eq-c">
        <div class="full-page bg-2 of-h">
            <div class="centered-logo text-white">
                <img src="../../assets/img/logo/logo.png" alt="Logo">
            </div>
        </div>
        <div class="full-page of-h">
            <div class="card bs-5 w-30r bg-1">
                <div>
                    <h4 class="text-primary">Login</h4>
                    <p class="text-primary">Login with your credentials</p>
                </div>
                <s-form ref="loginForm">
                    <validated-input icon="fa fa-user" name="Username" label="Username" v-model="model.username"
                                     :disabled="loading" :rules="rules.username" class="text-primary"/>
                    <validated-input icon="fa fa-lock" name="Password" label="Password" type="password"
                                     v-model="model.password" class="text-primary"
                                     :disabled="loading" :rules="rules.password"/>

                    <div class="card bg-danger mb-2" v-if="errorMessage" v-html="errorMessage"></div>

                    <btn @click="submitClicked" text="Login" loading-text="Validating..." size="block"
                         :disabled="loading" :loading="loading" class="text-white"/>
                </s-form>
            </div>
        </div>
    </div>
</template>

<script>
import urls from '@/data/urls';
import { mapActions } from 'vuex';
import axios from 'secure-axios';

export default {
    name: 'Login',
    data () {
        return {
            loginUrl: urls.auth.login,
            loading: false,
            model: {},
            errorMessage: '',
            rules: {
                username: {
                    required: true
                },
                password: {
                    required: true
                }
            }
        };
    },
    methods: {
        ...mapActions(['setUser']),
        async submitClicked () {
            const that = this;
            that.loading = true;
            try {
                const response = await axios.form(urls.auth.login, that.model);
                const json = response.data;
                if (!json) {
                    this.loginError();
                } else if (json.error === false) {
                    this.loginClicked(json);
                } else {
                    this.loginError();
                }
            } catch (error) {
                console.error(error);
            }
            that.loading = false;
        },
        formError (json) {
            this.$refs.loginForm.setErrors(json.errors);
            this.$notify('Please fix the issue.', 'Error', {
                type: 'danger'
            });
        },
        loginClicked (response) {
            if (response.user) {
                const redirectUrl = sessionStorage.getItem('redirectPath');
                this.setUser({
                    ...response.user
                });
                this.$router.push(redirectUrl || { path: '/app/' });
            }
            localStorage.clear();
        },
        loginError () {
            this.errorMessage = 'Invalid Credentials';
        }
    }
};
</script>
<style scoped>
.full-page {
    height: 100vh;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.centered-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.centered-logo img {
    max-width: 40%;
    max-height: 40%;
}
</style>
